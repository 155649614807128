//main.js

$(function() {

	$(document).on('click', '#menunav', function(e) {
		e.preventDefault();
		$('#menu').toggleClass('opened');
	});

});
